
const STORAGE_KEY = 'app';
export const storage = {
    get: (field) => {
        const app = localStorage.getItem(STORAGE_KEY);
        if (!app) {
            return undefined;
        } else {
            const data = JSON.parse(app);
            return data[field];
        }
    },
    set: (field, data) => {
        const app = localStorage.getItem(STORAGE_KEY);
        if (!app) {
            const cached = {};
            cached[field] = data;
            localStorage.setItem(STORAGE_KEY, JSON.stringify(cached));
        } else {
            const cached = JSON.parse(app);
            cached[field] = data;
            localStorage.setItem(STORAGE_KEY, JSON.stringify(cached));
        }
    },
    delete: (field) => {
        const app = localStorage.getItem(STORAGE_KEY);
        if (app) {
            const cached = JSON.parse(app);
            delete cached[field];
            localStorage.setItem(STORAGE_KEY, JSON.stringify(cached));
        }
    }
}