import { URL_API_GET_IP, URL_API_LOCATION } from "../const/const";

const getPublicIP = async () => {
    try {
    const resultGetIP = await fetch(URL_API_GET_IP);
    const dataGetIP = await resultGetIP.json();

    return {
        ip: dataGetIP.ip
    }
    } catch(error) {
        console.log('error', error);
        return undefined;
    }
}

const getLocation = async () => {
    try {
        const resultGetIP = await getPublicIP();
        if(resultGetIP) {
            const IP = resultGetIP?.ip;
    
            const resultGetLocation = await fetch(`${URL_API_LOCATION}/${IP}`);
            const dataGetLocation = await resultGetLocation.json();
    
            return {
                country: dataGetLocation.country,
                countryCode: dataGetLocation.country_code
            }
        }

        return undefined;
    } catch (error) {
        console.log('error', error);
        return undefined;
    }
}

export {
    getPublicIP,
    getLocation
}