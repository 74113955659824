import { useEffect } from "react";
import MainRouter from "./routes/routes";
import utils from "./utils";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "./hooks";
function App() {
  const location = useLocation();

  useEffect(() => {
    // Obtiene/Setea el origin desde URL
    (() => {
      const location = window.location;
      const paramsFromURL = location?.search
        ? new URLSearchParams(location.search)
        : undefined;

      if (paramsFromURL instanceof URLSearchParams) {
        if (paramsFromURL.get('o')) {
          utils.navigator.storage.set('origin', paramsFromURL.get('o'));
        }
      }
    })();

    // Obtiene la ubicación geográfica si no está configurada
    (() => {
      if(!location.current) {
        location.getLocation();
      }
    })();
  }, [])

  return (
    <div className="App">
      <MainRouter LandingPageLabel={"neowifi"} />
    </div>
  );
}

export default App;
