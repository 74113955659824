import { createContext, useState } from "react";
import utils from "../utils";

const LocationContext = createContext();

export const LocationProvider = (props) => {
    const locationFromStorage = utils.navigator.storage.get('location');
    const [location, setLocation] = useState(locationFromStorage);

    return (
        <LocationContext.Provider value={{
            location,
            setLocation
        }}>
            {props.children}
        </LocationContext.Provider>
    )
}

export default LocationContext;