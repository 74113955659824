import react from "react";
import { Nav, Navbar } from "react-bootstrap";
import SignUpTrackerButton from "./components/SignUpTrackerButton.component";
import lightLogo from "../../assets/images/logo-bw.svg";
import darkLogo from "../../assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export default function NeoValidadorNavBar({ children }) {
  return (
    <div
      data-spy="scroll"
      data-target="#navbar"
      data-offset="40"
      data-scroll-container
    >
      <Navbar bg="transparent" expand="lg" fixed="top" variant="dark">
        <div className="container">
          <Navbar.Brand href="/" className="logo">
            <img src={darkLogo} alt="" className="logo-dark" height="60" />
            <img src={lightLogo} alt="" className="logo-light" height="60" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll">
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="navbar-nav ms-auto navbar-center"
              id="navbar-navlist"
              navbarScroll
            >
              <Nav.Link href="/" className="nav-item">
                <span className="nav-link">Inicio</span>
              </Nav.Link>
              <Nav.Link href="/faq" className="nav-item">
                <span className="nav-link">Preguntas frecuentes</span>
              </Nav.Link>

              <Nav.Link href="/blog" className="nav-item">
                <span className="nav-link">Blog</span>
              </Nav.Link>
              <Nav.Link href="/cotizador" className="nav-item">
                <span to="/cotizador" className="nav-link">
                  Precios
                </span>
              </Nav.Link>
              <Nav.Link href="/contacto" className="nav-item">
                <span className="nav-link">Contacto</span>
              </Nav.Link>
              <a
                href="https://sistema.neovalidador.com/login"
                className="btn btn-sm rounded-pill btn-outline-light bg-primary nav-btn ms-lg-3"
              >
                Iniciar sesión
              </a>
              <a
                href="https://sistema.neovalidador.com/registro"
                className="btn btn-sm btn-secondary rounded-pill nav-btn ms-lg-3"
              >
                Abre tu cuenta
              </a>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/* <nav className="navbar navbar-expand-lg  fixed-top" id="navbar">
        <div className="container">
          <a className="navbar-brand logo" href="/">
            <img src={darkLogo} alt="" className="logo-dark" height="60" />
            <img src={lightLogo} alt="" className="logo-light" height="60" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars "></i>
          </button>
          <div
            className="collapse navbar-collapse text-center"
            id="navbarCollapse"
          >
            <ul
              className="navbar-nav ms-auto navbar-center"
              id="navbar-navlist"
            >
              <li className="nav-item">
                <Link to="/faq" className="nav-link">
                  Preguntas frecuentes
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/about" className="nav-link">
                  Sobre nosotros
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cotizador" className="nav-link">
                  Cotizador
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contacto" className="nav-link">
                  Contacto
                </Link>
              </li>
            </ul>
            <a
              href="https://sistema.neovalidador.com/login"
              className="btn btn-sm rounded-pill btn-outline-light bg-primary nav-btn ms-lg-3"
            >
              Iniciar sesión
            </a>
            <a
              href="https://sistema.neovalidador.com/registro"
              className="btn btn-sm btn-secondary rounded-pill nav-btn ms-lg-3"
            >
              Abre tu cuenta
            </a>
          </div>
        </div>
      </nav> */}
      {children}
    </div>
  );
}
