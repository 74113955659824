import React from "react";
import logo from "../../assets/images/logo-bw.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

export default function NeoValidadorFooter() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 order-lg-1 order-md-2 order-2 text-md-center mt-lg-0 mt-3">
          <div className="mb-4 text-lg-center text-md-left text-center">
            <a href="/">
              <img src={logo} alt="" className="img-fluid img-footer" height="70" />
            </a>
            <div className="w-100 d-flex justify-content-center mt-md-3">
              <a
                className="social-icon"
                href="https://www.facebook.com/NeoValidadormx"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                className="social-icon"
                href="https://www.instagram.com/NeoValidadormx"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                className="social-icon"
                href="https://www.linkedin.com/company/neovalidadormx"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-7 ms-lg-auto order-lg-2 order-md-1 order-1 mb-4 mb-md-0 text-lg-start text-center">
          <div className="row col-md-11 mx-auto justify-content-between">
            <div className="col-lg-4 col-md-3 col-12">
              <div className="mt-4 mt-lg-0">
                <h4 className="text-white font-size-18 mb-3">NeoValidador</h4>
                <ul className="list-unstyled footer-sub-menu">
                  <li>
                    {window.location.pathname !== "/" ? (
                      <a href="/#services" className="footer-link">
                        Beneficios
                      </a>
                    ) : (
                      <a
                        href="#services"
                        className="footer-link"
                        data-scroll-to
                      >
                        Beneficios
                      </a>
                    )}
                  </li>
                  <li>
                    {window.location.pathname !== "/" ? (
                      <a href="/#features" className="footer-link">
                        Características
                      </a>
                    ) : (
                      <a
                        href="#features"
                        className="footer-link"
                        data-scroll-to
                      >
                        Características
                      </a>
                    )}
                  </li>
                  <li>
                    {window.location.pathname !== "/" ? (
                      <a href="/" className="footer-link">
                        Contáctanos
                      </a>
                    ) : (
                      <a href="#home" className="footer-link" data-scroll-to>
                        Contáctanos
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-12">
              <div className="mt-4 mt-lg-0">
                <h4 className="text-white font-size-18 mb-3">Información</h4>
                <ul className="list-unstyled footer-sub-menu">
                  <li>
                    <a href="/faq" className="footer-link">
                      Preguntas frecuentes
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="footer-link">
                      Sobre nosotros
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://neovalidador.com/blog/"
                      className="footer-link"
                    >
                      Nuestro Blog
                    </a>
                  </li>
                  <li>
                    <a href="/cotizador" className="footer-link">
                      Cotizador
                    </a>
                  </li>
                  <li>
                    <a href="/aviso-de-privacidad" className="footer-link">
                      Aviso de privacidad
                    </a>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-12">
              <div className="mt-4 mt-lg-0">
                <h4 className="text-white font-size-18 mb-3">Familia NeoRed</h4>
                <ul className="list-unstyled footer-sub-menu">
                  <li>
                    <a
                      href="https://www.neored.com"
                      target="_blank"
                      className="footer-link"
                    >
                      NeoMailing
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.neoconecta.com"
                      target="_blank"
                      className="footer-link"
                    >
                      NeoConecta
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://neowifi.app/"
                      target="_blank"
                      className="footer-link"
                    >
                      NeoWifi
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="text-center mt-5">
            <p className="text-white-50 f-15 mb-0">
              {new Date().getFullYear()} © NeoValidador
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
