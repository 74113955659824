export const FREE_EMAIL_DOMAINS = [
    'gmail',
    'yahoo',
    'outlook',
    'hotmail',
    'icloud',
    'aol',
    'zoho',
    'gmx',
    'proton',
    'tutanota'
];

export const URL_AVISO_PRIVACIDAD =
  "/aviso-de-privacidad";

export const URL_API_GET_IP =
  "https://api.ipify.org/?format=json";

export const URL_API_LOCATION =
  "https://ipwhois.app/json";