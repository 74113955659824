import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
const NeoWifi = () => {
  const LandingPage = lazy(() => import("../projects/neowifi"));

  return (
    <Suspense fallback={null}>
      <LandingPage />
    </Suspense>
  );
};

export default NeoWifi;
