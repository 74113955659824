import { useContext } from 'react';
import LocationContext from '../context/location.context';
import * as services from '../services/location.service';
import utils from '../utils';

const useLocation = () => {
    const {
        location,
        setLocation
    } = useContext(LocationContext);

    const getLocation = async () => {
        let location = utils.navigator.storage.get('location');
        if(location) {
            return location;
        }

        location = await services.getLocation();
        setLocation(location);
        utils.navigator.storage.set('location', location);
        return location;
    }

    return {
        current: location,
        getLocation
    }
}

export default useLocation;