import React from "react";
import NeoValidador from "./NeoValidador";
import NeoWifi from "./NeoWifi";
import ProjectMenu from "./ProjectMenu";

const MainRouter = () => {
  return <NeoWifi />;
};

export default MainRouter;
